import { HttpRequest, HttpEvent, HttpHandlerFn, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';

export function AuthInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(request).pipe(
    tap(event => {
      // Check if the event is an HttpResponse
      if (event instanceof HttpResponse) {
        // Check if the response contains a Bearer token
        const bearerToken = event.headers.get('authorization');
        if (bearerToken && bearerToken.startsWith('Bearer ')) {
          // Extract the JWT token
          const token = bearerToken.split(' ')[1];
          // Store the token in localStorage (or sessionStorage)
          localStorage.setItem(environment.tokenKey, token);
        }
      }
    })
  );
}
