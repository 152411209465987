<ng-container *transloco="let t">
  <footer class="w-100 py-22">
    <div class="container d-flex justify-content-end">
      <span class="text-white">
        <a href="https://www.rh-brillenglas.de/de/informationen/impressum" target="_blank" class="text-white text-decoration-none pe-2 ">Impressum</a>
        &#124;
        <a href="https://www.rh-brillenglas.de/de/informationen/datenschutz" target="_blank" class="text-white text-decoration-none ps-2 ">Datenschutz</a>
      </span>
    </div>
  </footer>
</ng-container>
