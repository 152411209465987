import { Routes } from '@angular/router';
import { authGuard } from "@core/guards/auth.guard";
import { roleGuard } from './core/guards/role.guard';
export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadComponent: () => import('@home/home.component').then(m => m.HomeComponent) },
  { path: 'login', loadComponent: () => import('@user/login/login.component').then(m => m.LoginComponent) },
  { path: 'error', loadComponent: () => import('@misc/page-error/page-error.component').then(m => m.PageErrorComponent) },
  { path: '404', loadComponent: () => import('@misc/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent) },
  { path: 'purchases', loadChildren: () => import('@purchase/purchase.routes').then(m => m.PURCHASE_ROUTES), canActivate: [authGuard, roleGuard], data: { roles: ['admin','customer'] } },
  { path: 'mailing', loadChildren: () => import('@mailing/mailing.routes').then(m => m.MAILING_ROUTES) },
  { path: 'partner', loadChildren: () => import('@partner/partner.routes').then(m => m.PARTNER_ROUTES), canActivate: [authGuard, roleGuard], data: { roles: ['partner'] } },
  { path: 'auth/customer', loadComponent: () => import('@user/auth/auth.component').then(m => m.AuthComponent) },
  { path: 'auth/admin', loadComponent: () => import('@user/auth/auth.component').then(m => m.AuthComponent) },
  { path: 'auth/partner', loadComponent: () => import('@user/auth/auth.component').then(m => m.AuthComponent) },
  { path: '**', redirectTo: '/404' }
];
