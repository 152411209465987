<ng-container *transloco="let t">
  @for (toast of toastService.toasts; track toast) {
    <ngb-toast
      [header]="t(toast.header)" [autohide]="true" [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)">
      @if(toast.translate) {
        <p class="text-{{toast.type}}">
          {{ t(toast.body, toast.translateParams) }}
        </p>

      } @else {
        {{ toast.body }}
      }
    </ngb-toast>
  }
</ng-container>
