import {
  HttpRequest,
  HttpEvent, HttpHandlerFn
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

export function JwtInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const token = localStorage.getItem(environment.tokenKey);
  if (token) {
    const authReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next(authReq);
  }
  // No token present, proceed with the original request
  return next(request);
}
