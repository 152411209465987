import { Component } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { TranslocoModule } from '@jsverse/transloco';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-toast-container',
  standalone: true,
  imports: [TranslocoModule, NgbModule],
  templateUrl: './toast-container.component.html',
  styleUrl: './toast-container.component.scss'
})
export class ToastContainerComponent {
  constructor(public toastService: ToastService) {}
}
