import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from '@app/core/services/auth.service';
import { User } from '@app/shared/models/user.model';
import { Signal } from '@angular/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  userSignal: Signal<User | null>;

  constructor(private authService: AuthService) {
    this.userSignal = this.authService.getUserSignal();
  }

  logout(): void {
    this.authService.logout();
  }
}
