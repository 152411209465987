import {
  HttpRequest,
  HttpEvent,
  HttpErrorResponse, HttpHandlerFn
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

/*
import {inject} from "@angular/core";
import {Router} from "@angular/router";
*/

export function ApiErrorInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  // const router = inject(Router);
  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      /*
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        switch (error.status) {
          case 401:
            router.navigate(['/login']);
            break;
        }
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
      }
     */
      return throwError(() => error);
    })
  );
}
