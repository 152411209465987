import { Component, HostListener, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { User } from '@shared/models/user.model';
import { TranslocoModule } from "@jsverse/transloco";
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule, NgbDropdownModule, NgbCollapseModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  user: User | null = null;
  isNavbarCollapsed = true;

  // Reference to the dropdown state
  isDropdownOpen = false;

  constructor(private authService: AuthService) {
    const userSignal = this.authService.getUserSignal();
    effect(() => {
      this.user = userSignal();
    });
  }

  // Opens the dropdown on mouse enter
  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    this.isDropdownOpen = true;
  }

  // Closes the dropdown on mouse leave
  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.isDropdownOpen = false;
  }
}
